import ApiService from '@/common/api.service'

export const GET_LIST_LESSON = "/lesson/list";
export const GET_LIST_LESSON_BY_SUBJECT = "/lesson/list-lesson-by-subject";
export const GET_DETAIL_LESSON = "/lesson/detail";
export const CREATE_LESSON = "/lesson/save";
export const EDIT_LESSON = "/lesson/update";
export const DELETE_LESSON = "/lesson/delete";


export const SET_LIST_LESSON = "setListLesson";
export const SET_LESSON = "setLesson";


export default {
  state: () => ({
    lessons: [],
    lesson: {},
  }),
  getters: {
    lessons(state) {
      return state.lessons;
    },
    lesson(state) {
      return state.lesson;
    }
  },
  mutations: {
    [SET_LIST_LESSON](state, data) {
      state.lessons = data.data;
    },
    [SET_LESSON](state, data) {
      state.lesson = data.data;
    }
  },
  actions: {
    [GET_LIST_LESSON](context,payload) {
      return new Promise(resolve => {
        ApiService.query(GET_LIST_LESSON,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_LESSON, data);
          }
          resolve(data);
        });
      });
    },
    [GET_LIST_LESSON_BY_SUBJECT](context,payload) {
      return new Promise(resolve => {
        ApiService.query(GET_LIST_LESSON_BY_SUBJECT,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_LESSON, data);
          }
          resolve(data);
        });
      });
    },
    [GET_DETAIL_LESSON](context, payload) {
      return new Promise(resolve => {
        ApiService.query(GET_DETAIL_LESSON,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LESSON, data);
          }
          resolve(data);
        });
      });
    },
    [CREATE_LESSON](context, payload) {
      return new Promise(resolve => {
        ApiService.post(CREATE_LESSON, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [EDIT_LESSON](context, payload) {
      return new Promise(resolve => {
        ApiService.post(EDIT_LESSON, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [DELETE_LESSON](context, id) {
      return new Promise(resolve => {
        ApiService.post(`${DELETE_LESSON}/${id}`).then(({data}) => {
          resolve(data);
        });
      });
    },
  }
}