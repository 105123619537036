import ApiService from '@/common/api.service'

export const GET_LIST_GRADE = "/grade/list";
export const GET_DETAIL_GRADE = "/grade/detail";
export const CREATE_GRADE = "/grade/create";
export const EDIT_GRADE = "/grade/update";
export const DELETE_GRADE = "/grade/delete";


export const SET_LIST_GRADE = "setListGrade";
export const SET_GRADE = "setGrade";


export default {
  state: () => ({
    grades: [],
    grade: {},
  }),
  getters: {
    grades(state) {
      return state.grades;
    },
    grade(state) {
      return state.grade;
    }
  },
  mutations: {
    [SET_LIST_GRADE](state, data) {
      state.grades = data.data;
    },
    [SET_GRADE](state, data) {
      state.grade = data.data;
    }
  },
  actions: {
    [GET_LIST_GRADE](context) {
      return new Promise(resolve => {
        ApiService.query(GET_LIST_GRADE).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_GRADE, data);
          }
          resolve(data);
        });
      });
    },
    [GET_DETAIL_GRADE](context, payload) {
      return new Promise(resolve => {
        ApiService.query(GET_DETAIL_GRADE,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_GRADE, data);
          }
          resolve(data);
        });
      });
    },
    [CREATE_GRADE](context, payload) {
      return new Promise(resolve => {
        ApiService.post(CREATE_GRADE, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [EDIT_GRADE](context, payload) {
      return new Promise(resolve => {
        ApiService.post(EDIT_GRADE, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [DELETE_GRADE](context, id) {
      return new Promise(resolve => {
        ApiService.post(`${DELETE_GRADE}/${id}`).then(({data}) => {
          resolve(data);
        });
      });
    },
  }
}