<template>
  <el-menu
    :default-active="activeMenu"
    :unique-opened="true"
    :mode="mode"
    :collapse="isCollapse && mode !== 'horizontal'"
    :class="{ 'no-transition': isCollapse }"
  >
    <logo v-if="isShowLogo"></logo>
    <sidebar-item
      v-for="item in menuList"
      :key="item.menuId"
      :item="item"
      :collapse="collapse"
    ></sidebar-item>
  </el-menu>
</template>

<script>
import {reactive, toRefs, computed, watch, onMounted} from 'vue'
import {useStore} from 'vuex'
import Logo from '../Logo.vue'
import SidebarItem from './SidebarItem.vue'
import {getTabs} from '@/utils/storage'
import {setBreadcrumb} from '@/utils/storage'

export default {
  components: {Logo, SidebarItem},
  props: {
    mode: String,
    showLogo: Boolean,
    collapse: Boolean
  },
  setup(props) {
    const store = useStore()
    const collapse = props.collapse
    const data = reactive({
      activeMenu: '',
      menuList: [
        {menuId: 'admins', menuName: 'Quản lý Admin', path: '/admin/list', children: []},
        {menuId: 'typeUser', menuName: 'Danh mục', path: '/type-user/list', children: []},
        // {menuId: 'category', menuName: 'Chuyên mục', path: '/category/list', children: []},
        {menuId: 'lesson', menuName: 'Bài viết', path: '/lesson/list', children: []},
        {menuId: 'users', menuName: 'Người dùng', path: '/user/list', children: []},
        // {menuId: 'grade', menuName: 'Lớp học', path: '/grade/list', children: []},
        {
          menuId: 'document',
          menuName: 'Tài liệu',
          path: '',
          children: [
            {menuId: 'document_approve', menuName: 'Tài liệu cần xác nhận', path: '/documents', children: []},
            {menuId: 'document_list', menuName: 'Danh sách tài liệu', path: '/documents/list', children: []},
          ]
        },
        {menuId: 'setting', menuName: 'Setting', path: '', children: [
            {menuId: 'introduction', menuName: 'Giới thiệu', path: '/setting/introduction', children: []},
            {menuId: 'contact', menuName: 'Thông tin liên hệ', path: '/setting/contact', children: []},
            {menuId: 'privacy-policy', menuName: 'Chính sách bảo mật', path: '/setting/privacy-policy', children: []},
            {menuId: 'terms-of-use', menuName: 'Điều khoản sử dụng', path: '/setting/terms-of-use', children: []},
          ]}
      ]
    })

    // 是否显示Logo
    const isShowLogo = computed(() => {
      return props.showLogo
    })

    // 是否折叠菜单
    const isCollapse = computed(() => {
      if (props.collapse) {
        return collapse
      } else {
        return store.state.isCollapse
      }
    })

    const _tabs = getTabs()
    _tabs.forEach((item) => {
      if (item.active) data.activeMenu = item.id
    })

    const _getParentMenu = (list, id) => {
      for (let i in list) {
        if (list[i].menuId == id) {
          return [list[i]]
        }
        if (list[i].children) {
          let node = _getParentMenu(list[i].children, id)
          if (node !== undefined) {
            return node.concat(list[i])
          }
        }
      }
    }
    setBreadcrumb(_getParentMenu(data.menuList, data.activeMenu))

    watch(
      () => store.state.activeMenu,
      (value, old) => {
        data.activeMenu = value
        setBreadcrumb(_getParentMenu(data.menuList, value))
      }
    )

    const params = toRefs(data)
    return {
      ...params,
      isShowLogo,
      isCollapse
    }
  }
}
</script>

<style></style>
