import ApiService from '@/common/api.service'

export const GET_LIST_USER = "/user/list";
export const GET_DETAIL_USER = "/user/detail";
export const UPDATE_USER = "/user/update";

export const SET_LIST_USER = "setListUser";
export const SET_DETAIL_USER = "setDetailUser";


export default {
    state : () =>({
        users: [],
        user: {},
        total_user:0,
    }),
    getters :{
        users(state) {
            return state.users;
        },
        userDetail(state) {
            return state.user;
        },
        total_user(state) {
            return state.total_user;
        }
    },
    mutations :{
        [SET_LIST_USER](state,data) {
            state.users = data.data;
            state.total_user = data.total;
        },
        [SET_DETAIL_USER](state,data) {
            state.user = data.data;
        }
    },
    actions : {
        [GET_LIST_USER](context,payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(GET_LIST_USER,payload).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_LIST_USER, data.data);
                    }
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
        [GET_DETAIL_USER](context,payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(GET_DETAIL_USER,payload).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_USER, data);
                    }
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
        [UPDATE_USER](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(UPDATE_USER, payload).then(({data}) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
    }
}