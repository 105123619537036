<template>
  <layout>
    <template #home>
      <el-row>
        <el-col :span="24" :xl="23" :lg="23" >
          <router-view/>
        </el-col>
      </el-row>
    </template>
  </layout>
</template>

<script>
import Layout from './Layout.vue'
import Home from '@/views/Home.vue'

export default {
  components: {Layout, Home}
}
</script>

<style lang="scss">
.el-row {
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
