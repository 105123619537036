import ApiService from '@/common/api.service'

export const GET_LIST_DOC_BY_USER = "/documents/list-by-user";
export const GET_DETAL_DOC_BY_USER = "/documents/detail-by-id";
export const GET_DETAL_DOC_BY_SLUG = "/documents/detail-by-slug";

export const SET_LIST_DOC_BY_USER = "setListDocByUser";
export const SET_DETAIL_DOC_BY_USER = "setDetailDocByUser";
export const SET_DETAIL_DOC_BY_SLUG = "setDetailDocBySlug";
export const GET_LIST_DOC = "/documents/list";
export const GET_LIST_DOC_APPROVE = "/documents/list-approve";
export const SAVE_DOCUMENT = 'documents/save';
export const UPDATE_DOCUMENT = 'documents/update';
export const UPDATE_DOCUMENT_CATEGORY = 'documents/update-category';
export const UPDATE_DOCUMENT_PARENT_ID = "/documents/update-parent";
export const UPDATE_DOCUMENT_ACTIVE= 'documents/update-active';
export const SEARCH_DOCUMENT = 'documents/search';
export const DELETE_DOCUMENT = 'documents/delete';
export const GET_LIST_DOCUMENT_BY_CATEGORY_ID = 'documents/list-by-category';

export const SET_LIST_DOC = "setList";
export const SET_LIST_DOCUMENT_BY_CATEGORY_ID = "setListByCategory";
export const SET_SEARCH_DOC = "searchList";
export const SET_LIST_DOC_APPROVE = "setListDocumentApprove";

export const UPDATE_PLAY = "/documents/update_play";
export default {
  state: () => ({
    docs_by_user: [],
    docs_by_slug: [],
    docs: [],
    searchs:[],
    list_by_categories:[],
    detail_doc: {},
    docs_approve: [],
    total_document:0,
  }),
  getters: {
    docByUser(state) {
      return state.docs_by_user;
    },
    list_by_categories(state) {
      return state.list_by_categories;
    },
    total_document(state) {
      return state.total_document;
    },
    docs_by_slug(state) {
      return state.docs_by_slug;
    },
    docs(state) {
      return state.docs;
    },
    searchs(state) {
      return state.searchs;
    },
    detail_doc(state) {
      return state.detail_doc;
    },
    docs_approve(state) {
      return state.docs_approve;
    }
  },
  mutations: {
    [SET_LIST_DOC_BY_USER](state, data) {
      state.docs_by_user = data.data;
    },
    [SET_DETAIL_DOC_BY_SLUG](state, data) {
      state.docs_by_slug = data.data;
    },
    [SET_LIST_DOCUMENT_BY_CATEGORY_ID](state, data) {
      state.list_by_categories = data.data;
      state.total_document = data.total;
    },
    [SET_LIST_DOC](state, data) {
      state.docs = data.data;
      state.total_document = data.total;
    },
    [SET_SEARCH_DOC](state, data) {
      state.searchs = data.data;
      state.total_document = data.total;
    },
    [SET_DETAIL_DOC_BY_USER](state,data) {
      state.detail_doc = data.data;
    },
    [SET_LIST_DOC_APPROVE](state, data) {
      state.docs_approve = data.data;
    }
  },
  actions: {
    [UPDATE_DOCUMENT_PARENT_ID](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_DOCUMENT_PARENT_ID, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_DOC_BY_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.get(`${GET_LIST_DOC_BY_USER}/${payload}`).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_DOC_BY_USER, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_DOCUMENT_BY_CATEGORY_ID](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_DOCUMENT_BY_CATEGORY_ID,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_DOCUMENT_BY_CATEGORY_ID, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_DOC_APPROVE](context) {
      return new Promise((resolve, reject) => {
        ApiService.get(`${GET_LIST_DOC_APPROVE}`).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_DOC_APPROVE, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_PLAY](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_PLAY, payload).then(({data}) => {
          if (data.status) {
            resolve(data);
          }
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [DELETE_DOCUMENT](context, id) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${DELETE_DOCUMENT}/${id}`).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [SAVE_DOCUMENT](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(SAVE_DOCUMENT, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_DOCUMENT](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_DOCUMENT, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_DOCUMENT_CATEGORY](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_DOCUMENT_CATEGORY, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_DOCUMENT_ACTIVE](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_DOCUMENT_ACTIVE, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_DOC](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_DOC, payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_DOC, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_DETAL_DOC_BY_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(GET_DETAL_DOC_BY_USER,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_DETAIL_DOC_BY_USER, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [SEARCH_DOCUMENT](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(SEARCH_DOCUMENT,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_SEARCH_DOC, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_DETAL_DOC_BY_SLUG](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(GET_DETAL_DOC_BY_SLUG,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_DETAIL_DOC_BY_SLUG, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  }
}