import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/layout.scss'
import '@/assets/css/custom.scss'
import SvgIcon from '@/components/SvgIcon.vue'
import CKEditor from '@ckeditor/ckeditor5-vue';
import Toaster from "@meforma/vue-toaster";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

let app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store)
app.use(Toaster)
app.use(router)
app.use(ElementPlus)
app.use(CKEditor)
app.component('SvgIcon', SvgIcon)
app.mount('#app')
