<template>
  <el-dropdown>
    <div class="header-user">
      <img src="@/assets/img/user.jpg" alt="" />
      <span>{{user.name ? user.name : 'admin'}}</span>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item icon="el-icon-user">Personal information</el-dropdown-item>
        <el-dropdown-item icon="el-icon-lock">change Password</el-dropdown-item>
        <el-dropdown-item icon="el-icon-switch-button" @click="logout">sign out</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import {GET_DETAIL_USER,LOGOUT} from "@/store/auth";
import {useStore} from "vuex";
import {useRouter} from 'vue-router';
import {computed} from "vue";
const store = useStore();
const router = useRouter();
store.dispatch(GET_DETAIL_USER);
const user = computed(() => store.getters.user)
const logout = () => {
  store.dispatch(LOGOUT);
  router.push('/login')
}
</script>

<style lang="scss" scoped>
.header-user {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 4px;
  cursor: pointer;
  color: #ffffff;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>
