<template>
  <div class="el-logo d-flex justify-center">
    <img src="@/assets/logo-topdethi.png" alt="" width="150"  height="48"  style="object-fit: contain"/>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'Logo',
  setup() {
    const store = useStore()
    const isHideTitle = computed(() => {
      return store.state.isCollapse
    })

    return {
      isHideTitle
    }
  }
}
</script>
<style scoped lang="scss">
.el-logo {
  justify-content: center;
  img {
    width: 150px;
  }
}
</style>
