<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="fullscreen ? 'Exit Full Screen' : 'full screen'"
    placement="bottom"
  >
    <svg-icon v-if="!fullscreen" name="full-screen-max" @click="handleFullScreen"></svg-icon>
    <svg-icon :sss="fullscreen" v-else name="full-screen-min" @click="handleFullScreen"></svg-icon>
  </el-tooltip>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { changeFullScreen, listenerEvent } from './fullScreen'
export default {
  setup() {
    const data = reactive({
      fullscreen: false
    })
    listenerEvent(() => {
      data.fullscreen = !data.fullscreen
    }, data)
    const handleFullScreen = () => changeFullScreen(data)
    const params = toRefs(data)
    return {
      ...params,
      handleFullScreen
    }
  }
}
</script>

<style></style>
