import {createStore} from 'vuex'
import {getThemes} from '@/utils/storage'
import category from "./category";
import user from "./user";
import document from "./document";
import auth from "./auth";
import type_users from "./typeUser";
import grade from "./grade";
import subject from "./subject";
import lesson from "./lesson";
import admin from "./admin";
import ttl_lesson from "./ttlLesson";
import setting from "./setting";

const navbarType = getThemes()?.navbarType ?? 'left_menu_mode'
export default createStore({
  state: {
    navbarType: navbarType,
    isCollapse: false,
    activeMenu: ''
  },
  mutations: {
    getNavbarType(state, data) {
      state.navbarType = data
    },

    getCollapse(state, data) {
      state.isCollapse = data
    },

    getActiveMenu(state, data) {
      state.activeMenu = data
    }
  },
  actions: {},
  modules: {
    auth,
    document,
    category,
    type_users,
    user,
    lesson,
    grade,
    subject,
    ttl_lesson,
    admin,
    setting,
  }
})
