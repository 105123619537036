import {createRouter, createWebHistory} from 'vue-router'
import Layout from '@/components/layout/index.vue'

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: '',
        component: () => import('@/views/Home.vue')
      },
      // {
      //   path: 'ckeditor',
      //   name: 'ckeditor',
      //   component: () => import('@/views/ck-content.vue')
      // },
      {
        path: 'admin/list',
        name: 'admin-list',
        component: () => import('@/views/pages/admin/list.vue')
      },
      {
        path: 'admin/add',
        name: 'admin-add',
        component: () => import('@/views/pages/admin/create.vue')
      },
      {
        path: 'admin/detail',
        name: 'admin-detail',
        component: () => import('@/views/pages/admin/detail.vue')
      },
      //user
      {
        path: 'user/list',
        name: 'user-list',
        component: () => import('@/views/pages/user/list.vue')
      },
      {
        path: 'user/edit',
        name: 'user-edit',
        component: () => import('@/views/pages/user/edit.vue')
      },
      {
        path: 'user/documents',
        name: 'user-documents',
        component: () => import('@/views/pages/user/documents-by-user.vue')
      },
      //type user
      {
        path: 'type-user/list',
        name: 'type-user-list',
        component: () => import('@/views/pages/type-user/list.vue')
      },
      {
        path: 'type-user/create',
        name: 'type-user-create',
        component: () => import('@/views/pages/type-user/create.vue')
      },
      {
        path: 'type-user/edit/:slug',
        name: 'type-user-edit',
        component: () => import('@/views/pages/type-user/edit.vue')
      },
      {
        path: 'grade-user/list/:id',
        name: 'grade-user-list',
        component: () => import('@/views/pages/type-user/grade-user.vue')
      },
      //lesson
      {
        path: 'lesson/list/',
        name: 'lesson-list',
        component: () => import('@/views/pages/lesson/list.vue')
      },
      {
        path: 'lesson/create/',
        name: 'lesson-create',
        component: () => import('@/views/pages/lesson/create.vue')
      },
      {
        path: 'lesson/edit/',
        name: 'lesson-edit',
        component: () => import('@/views/pages/lesson/edit.vue')
      },
      //document
      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/pages/documents/index.vue')
      },
      {
        path: 'documents/list',
        name: 'documents-list',
        component: () => import('@/views/pages/documents/list.vue')
      },
      {
        path: 'documents/create',
        name: 'documents-create',
        component: () => import('@/views/pages/documents/create.vue')
      },
      {
        path: 'documents/edit',
        name: 'documents-edit',
        component: () => import('@/views/pages/documents/update.vue')
      },
      {
        path: 'documents/detail',
        name: 'document_detail',
        component: () => import('./../views/pages/documents/detail.vue')
      },
      {
        path: 'setting/introduction',
        name: 'introduction-setting',
        component: () => import('../views/pages/setting/introduction.vue')
      },
      {
        path: 'setting/contact',
        name: 'contact-setting',
        component: () => import('../views/pages/setting/contact.vue')
      },
      {
        path: 'setting/privacy-policy',
        name: 'privacy-policy-setting',
        component: () => import('../views/pages/setting/privacy-policy.vue')
      },
      {
        path: 'setting/terms-of-use',
        name: 'terms-of-use-setting',
        component: () => import('../views/pages/setting/terms-of-use.vue')
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
    name: 'login'
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes
})
export default router
