import ApiService from '@/common/api.service';

export const GET_SETTING_HOMEPAGE = "/setting";
export const UPDATE_SETTING_HOMEPAGE = "/update-setting-homepage";
export const SET_SETTING_HOMEPAGE = "settingHomepage";

export default {
    state: () => ({
        setting_homepage: {},
    }),
    getters: {
        SettingHomePage(state) {
            return state.setting_homepage;
        },

    },
    mutations: {
        [SET_SETTING_HOMEPAGE](state, data) {
            state.setting_homepage = data;
        },

    },
    actions: {
        [GET_SETTING_HOMEPAGE](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(GET_SETTING_HOMEPAGE, payload).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_SETTING_HOMEPAGE, data.data);
                    }
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        [UPDATE_SETTING_HOMEPAGE](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(UPDATE_SETTING_HOMEPAGE, payload).then(({data}) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
    }
}