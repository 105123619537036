import ApiService from '@/common/api.service'

export const GET_LIST_CATEGORY = "/categories/list";
export const GET_LIST_CATEGORY_BY_USER_TYPE = "/categories/list-by-type";
export const SET_LIST_CATEGORY = "setListCategory";

export const GET_CATEGORY_DETAIL = "/categories/edit";
export const SET_CATEGORY_DETAIL = "setCategoryDetail";

export const CREATE_CATEGORY = "categories/create";
export const UPDATE_CATEGORY = "categories/update";
export const DELETE_CATEGORY = "categories/delete";

export default {
    state : () =>({
        categories: [],
        category: {},
    }),
    getters :{
        categories(state) {
            return state.categories;
        },
        category(state) {
            return state.category;
        }
    },
    mutations :{
        [SET_LIST_CATEGORY](state, data) {
            state.categories = data.data;
        },
        [SET_CATEGORY_DETAIL](state, data) {
            state.category = data.data;
        },
    },
    actions : {
        [GET_LIST_CATEGORY](context) {
            return new Promise((resolve, reject) => {
                ApiService.query(GET_LIST_CATEGORY).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_LIST_CATEGORY, data);
                    }
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
      [GET_LIST_CATEGORY_BY_USER_TYPE](context, payload) {
        return new Promise((resolve, reject) => {
          ApiService.query(GET_LIST_CATEGORY_BY_USER_TYPE, payload).then(({data}) => {
            if (data.status) {
              context.commit(SET_LIST_CATEGORY, data);
            }
            resolve(data);
          }).catch((err) => {
            reject(err);
          });
        });
      },

        [GET_CATEGORY_DETAIL](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.query(GET_CATEGORY_DETAIL, payload).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_CATEGORY_DETAIL, data);
                    }
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
        [CREATE_CATEGORY](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(CREATE_CATEGORY, payload).then(({data}) => {
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
        [UPDATE_CATEGORY](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(UPDATE_CATEGORY, payload).then(({data}) => {
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
        [DELETE_CATEGORY](context, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(`${DELETE_CATEGORY}/${payload}`).then(({data}) => {
                    resolve(data);
                }).catch((err) => {
                  reject(err);
                });
            });
        },
    }
}