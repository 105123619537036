import ApiService from '@/common/api.service'
import JwtService from "@/common/jwt.service";

export const LOGIN = "/login";
export const LOGOUT = "logout";
export const GET_DETAIL_USER = "/get-detail-user";
export const SET_USER = "/setUser";
export const SET_AUTH = "/setAuth";
export const PURGE_AUTH = "logOut";

export default {
    state: () => ({
        user: {},
      isAuthenticated: !!JwtService.getToken()
    }),
    getters: {
        user(state) {
            return state.user;
        },
      isAuthenticated(state) {
        return state.isAuthenticated;
      }
    },
    mutations: {
        [SET_AUTH](state, data) {
          state.isAuthenticated = true;
            JwtService.saveToken(data.token);
            ApiService.setHeader();
        },
        [SET_USER](state, data) {
            state.user = data
        },
      [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
      }
    },
    actions: {
        [LOGIN](context, payload) {
            return new Promise(resolve => {
                ApiService.post(LOGIN, payload).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_AUTH, data.data);
                    }
                    resolve(data);
                });
            });
        },
      [LOGOUT](context) {
        context.commit(PURGE_AUTH);
      },
        [GET_DETAIL_USER](context) {
            return new Promise(resolve => {
                ApiService.query(GET_DETAIL_USER).then(({data}) => {
                    if (data.status) {
                        context.commit(SET_USER, data.data);
                    }
                    resolve(data);
                });
            });
        },
    }
}
