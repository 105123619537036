import ApiService from '@/common/api.service';

export const GET_LIST_TTL_LESSON = "/lesson-child/list";
export const GET_LIST_LESSON_BY_CATEGORY = "/lesson-child/list-by-category";
export const GET_DETAIL_TTL_LESSON = "/lesson-child/detail";
export const GET_SEARCH_TTL_LESSON = "/lesson-child/search-lesson";
export const CREATE_TTL_LESSON = "/lesson-child/add-new";
export const EDIT_TTL_LESSON = "/lesson-child/update";
export const UPDATE_CATEGORY_TTL_LESSON = "/lesson-child/update-category";
export const DELETE_TTL_LESSON = "/lesson-child/delete";
export const UPLOAD_FILE = "/upload-file";
export const UPLOAD_IMAGE = "/lesson/image/upload";
export const UPDATE_LESSON_PARENT_ID = "/lesson-child/update-parent";


export const SET_LIST_TTL_LESSON = "setListLesson";
export const SET_SEARCH_TTL_LESSON = "setSearchLesson";
export const SET_LIST_TTL_LESSON_BY_CATEGORY = "setListLessonByCategory";
export const SET_TTL_LESSON = "setLesson";


export default {
  state: () => ({
    ttl_lessons: [],
    ttl_lesson: {},
    lesson_by_categories: [],
    searchs:[],
    totalLesson: 0,
  }),
  getters: {
    ttlLessons(state) {
      return state.ttl_lessons;
    },
    searchLessons(state) {
      return state.searchs;
    },
    ttlLesson(state) {
      return state.ttl_lesson;
    },
    totalLesson(state) {
      return state.totalLesson;
    },
    lesson_by_categories(state) {
      return state.lesson_by_categories;
    }
  },
  mutations: {
    [SET_LIST_TTL_LESSON](state, data) {
      state.ttl_lessons = data.data;
      state.totalLesson = data.total;
    },
    [SET_SEARCH_TTL_LESSON](state, data) {
      state.searchs = data.data;
      state.totalLesson = data.total;
    },
    [SET_TTL_LESSON](state, data) {
      state.ttl_lesson = data.data;
    },
    [SET_LIST_TTL_LESSON_BY_CATEGORY](state, data) {
      state.lesson_by_categories = data.data;
      state.totalLesson = data.total;
    }
  },
  actions: {
    [UPDATE_LESSON_PARENT_ID](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_LESSON_PARENT_ID, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPLOAD_IMAGE](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPLOAD_IMAGE, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_TTL_LESSON](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_TTL_LESSON,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_TTL_LESSON, data.data);
          }
          resolve(data);
        }).catch((err) => {
            reject(err);
          });
      });
    },
    [GET_SEARCH_TTL_LESSON](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_SEARCH_TTL_LESSON,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_SEARCH_TTL_LESSON, data.data);
          }
          resolve(data);
        }).catch((err) => {
            reject(err);
          });
      });
    },

    [GET_LIST_LESSON_BY_CATEGORY](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_LESSON_BY_CATEGORY,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_TTL_LESSON_BY_CATEGORY, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },

    [GET_DETAIL_TTL_LESSON](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(`${GET_DETAIL_TTL_LESSON}/${payload.id}`).then(({data}) => {
          if (data.status) {
            context.commit(SET_TTL_LESSON, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [CREATE_TTL_LESSON](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(CREATE_TTL_LESSON, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPLOAD_FILE](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPLOAD_FILE, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [EDIT_TTL_LESSON](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${EDIT_TTL_LESSON}/${payload.id}`, payload.form).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_CATEGORY_TTL_LESSON](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${UPDATE_CATEGORY_TTL_LESSON}`, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [DELETE_TTL_LESSON](context, id) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${DELETE_TTL_LESSON}/${id}`).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  }
}
