import ApiService from '@/common/api.service'

export const GET_LIST_ADMIN = "/list-admin";
export const GET_DETAIL_ADMIN = "/detail-admin";
export const ADD_NEW_ADMIN = "/register";
export const UPDATE_ADMIN = "/update-admin";
export const DELETE_ADMIN = "/delete-admin";
export const SET_LIST_ADMIN = "setListAdmin";
export const SET_DETAIL_ADMIN = "setDetailAdmin";


export default {
  state : () =>({
    admins: [],
    admin:{},
    total_admin:0,
  }),
  getters :{
    admins(state) {
      return state.admins;
    },
    detailAdmin(state) {
      return state.admin;
    },
    total_admin(state) {
      return state.total_admin;
    }
  },
  mutations :{
    [SET_LIST_ADMIN](state,data) {
      state.admins = data.data;
      state.total_admin = data.total;
    },
    [SET_DETAIL_ADMIN](state,data) {
      state.admin = data.data;
    }
  },
  actions : {
    [GET_LIST_ADMIN](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_ADMIN,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_ADMIN, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_DETAIL_ADMIN](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_DETAIL_ADMIN,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_DETAIL_ADMIN, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [ADD_NEW_ADMIN](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(ADD_NEW_ADMIN, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_ADMIN](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_ADMIN, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [DELETE_ADMIN](context, id) {
      return new Promise((resolve, reject) => {
        ApiService.delete(DELETE_ADMIN, id).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  }
}
