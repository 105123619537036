import ApiService from '@/common/api.service'

export const GET_LIST_TYPE_USER = "/type-user/list";
export const GET_SEARCH_TYPE_USER = "/type-user/search-type-user";
export const GET_LIST_GRADE_BY_TYPE_USER = "/type-user/list-grade";
export const GET_DETAIL_TYPE_USER = "/type-user/detail";
export const CREATE_TYPE_USER = "/type-user/create";
export const CHANGE_STATUS_TYPE_USER = "/type-user/change-status";
export const CREATE_GRADE_BY_TYPE_USER = "/type-user/create-grade";
export const EDIT_TYPE_USER = "/type-user/update";
export const UPDATE_PARENT_ID = "/type-user/update-parent";
export const DELETE_TYPE_USER = "/type-user/delete";
export const DELETE_GRADE_BY_TYPE_USER = "/type-user/delete-grade";


export const SET_LIST_TYPE_USER = "setListTypeUser";
export const SET_SEARCH_TYPE_USER = "setSearchTypeUser";
export const SET_LIST_GRADE_BY_TYPE_USER = "setListGradeByTypeUser";
export const SET_TYPE_USER = "setTypeUser";


export default {
  state: () => ({
    type_users: [],
    searchs: [],
    gradeUsers: [],
    gradeUserIds: [],
    type_user: {},
    total_category: 0,
    total_parent: 0,
  }),
  getters: {
    type_users(state) {
      return state.type_users;
    },
    searchCategory(state) {
      return state.searchs;
    },
    gradeUserIds(state) {
      return state.gradeUserIds;
    },
    gradeUsers(state) {
      return state.gradeUsers;
    },
    type_user(state) {
      return state.type_user;
    },
    total_category(state) {
      return state.total_category;
    },
    total_parent(state) {
      return state.total_parent;
    }
  },
  mutations: {
    [SET_LIST_TYPE_USER](state, data) {
      state.type_users = data.data;
      state.total_category = data.total;
    },
    [SET_SEARCH_TYPE_USER](state, data) {
      state.searchs = data.data;
      state.total_parent = data.total;
    },
    [SET_LIST_GRADE_BY_TYPE_USER](state, data) {
      state.gradeUsers = data.data.grades;
      state.gradeUserIds = data.data.gradeIds;
    },
    [SET_TYPE_USER](state, data) {
      state.type_user = data.data;
    }
  },
  actions: {
    [GET_LIST_TYPE_USER](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_TYPE_USER,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_TYPE_USER, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_SEARCH_TYPE_USER](context,payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_SEARCH_TYPE_USER,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_SEARCH_TYPE_USER, data.data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_LIST_GRADE_BY_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_LIST_GRADE_BY_TYPE_USER, payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_GRADE_BY_TYPE_USER, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [GET_DETAIL_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.query(GET_DETAIL_TYPE_USER, payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_TYPE_USER, data);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [CREATE_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(CREATE_TYPE_USER, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [UPDATE_PARENT_ID](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(UPDATE_PARENT_ID, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [CHANGE_STATUS_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(CHANGE_STATUS_TYPE_USER, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [CREATE_GRADE_BY_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(CREATE_GRADE_BY_TYPE_USER, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [EDIT_TYPE_USER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(EDIT_TYPE_USER, payload).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [DELETE_TYPE_USER](context, id) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${DELETE_TYPE_USER}/${id}`).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    [DELETE_GRADE_BY_TYPE_USER](context, id) {
      return new Promise((resolve, reject) => {
        ApiService.post(`${DELETE_GRADE_BY_TYPE_USER}/${id}`).then(({data}) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  }
}
