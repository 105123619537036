<template>
  <el-row :gutter="16">
    <el-col :xs="24" :sm="12" :md="6">
      <el-card shadow="never">
        <template #header>
          <span class="left">
            Number of visits</span>
          <span class="right card-header-label green">year</span>
        </template>
        <div class="card-body">
          <span class="num">64353</span>
          <div class="flex-justify-between bottom">
            <span>Total visits</span>
            <span>265432</span>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="6">
      <el-card shadow="never">
        <template #header>
          <span class="left">Downloads</span>
          <span class="right card-header-label blue">moon</span>
        </template>
        <div class="card-body">
          <span class="num">2542</span>
          <div class="flex-justify-between bottom">
            <span>Total downloads</span>
            <span>14365</span>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="6">
      <el-card shadow="never">
        <template #header>
          <span class="left">Number of transactions</span>
          <span class="right card-header-label orange">week</span>
        </template>
        <div class="card-body">
          <span class="num">1434</span>
          <div class="flex-justify-between bottom">
            <span>Total number of transactions</span>
            <span>1455</span>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="6">
      <el-card shadow="never">
        <template #header>
          <span class="left">Number of collections</span>
          <span class="right card-header-label purple">日</span>
        </template>
        <div class="card-body">
          <span class="num">4692</span>
          <div class="flex-justify-between bottom">
            <span>Total number of collections</span>
            <span>87491</span>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-card shadow="never">
        <div class="details">
          <span>Technology stack：</span>
          vue3.x + vite2.x + vuex4.x + vue-router4.x + element Plus1.x
        </div>
        <div class="details">
          <span>Features：</span>
          One-click skinning, custom color skinning, multiple layout methods, easy to use
        </div>
        <div class="details">
          <span>author：</span>
          wuufeii
        </div>
        <div class="details">
          <span>github：</span>
          <a href="https://www.github.com/wuufeii" target="_blank">
            https://www.github.com/wuufeii
          </a>
        </div>
        <div class="details">
          <span>Blogger：</span>
          <a href="https://wuufeii.github.io" target="_blank">https://wuufeii.github.io</a>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script setup>
import {ref, reactive} from "vue";
import {useRouter} from 'vue-router';
import { useStore } from 'vuex';
import { computed, watch } from 'vue';
import {GET_LIST_CATEGORY,
  CREATE_CATEGORY,
  GET_CATEGORY_DETAIL,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from '@/store/category';

//created
const store = useStore()
const router = useRouter()
let value = ref(true)
store.dispatch(GET_LIST_CATEGORY)
//computed
const categories = computed(() => store.getters.categories)
const category = computed(() => store.getters.category)

//data
let list = ref([]);
let name = ref('');
let category_id = ref(null);
let isEdit = ref(false);
let disable = ref(true);

//method
const createCategory = () => {
  store.dispatch(CREATE_CATEGORY, {name: name.value}).then(data => {
    if (data.status){
      name.value = '';
      store.dispatch(GET_LIST_CATEGORY);
    }
  });
}

const editCategory = (id) => {
  store.dispatch(GET_CATEGORY_DETAIL, id);
  isEdit.value = true;
}

const deleteCategory = (id) => {
  if (confirm("Bạn muốn xóa danh mục này?")) {
    store.dispatch(DELETE_CATEGORY, id).then(data => {
      if (data.status) {
        store.dispatch(GET_LIST_CATEGORY);
      }
    });
  }
}

const clearCategory = () => {
  isEdit.value = false;
  category_id.value = null;
  name.value = '';
}

const updateCategory = () => {
  let payload = {
    id: category_id.value,
    name: name.value,
  }
  store.dispatch(UPDATE_CATEGORY, payload).then(data => {
    if (data.status) {
      category_id.value = null;
      name.value = '';
      store.dispatch(GET_LIST_CATEGORY);
    }
  });
}

//watch
watch(name, (newVal, oldVal) => {
  if (newVal == "") {
    isEdit.value = false;
    disable.value = true;
  } else {
    disable.value = false;
  }
})

watch(categories, (newVal, oldVal) => {
  if (newVal) {
    list.value = newVal
  }
})

watch(category, (newVal, oldVal) => {
  if (newVal) {
    console.log(newVal.id)
    name.value = newVal.name
    category_id.value = newVal.id
  }
})
</script>

<style lang="scss" scoped>
.el-row {
  .el-col {
    margin-bottom: 1rem;
  }
}
.card-header-label {
  font-size: 12px;
  padding: 2px 7px;
  border: 1px solid #eee;
  &.green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  &.blue {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
  &.orange {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
  }
  &.purple {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
}
.card-body {
  padding: 0.5rem 1rem;
  .num {
    display: inline-block;
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  .bottom {
    font-size: 0.875rem;
  }
}
.details {
  padding: 0.5rem 1rem;
  > span {
    color: #999;
  }
}
</style>
