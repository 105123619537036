import ApiService from '@/common/api.service'

export const GET_LIST_SUBJECT = "/subject/list";
export const GET_LIST_SUBJECT_BY_GRADE = "/subject/list-subject-by-grade";
export const GET_ALL_SUBJECT = "/subject/all";
export const GET_DETAIL_SUBJECT = "/subject/detail";
export const CREATE_SUBJECT = "/subject/save";
export const EDIT_SUBJECT = "/subject/update";
export const DELETE_SUBJECT = "/subject/delete";


export const SET_LIST_SUBJECT = "setListSubject";
export const SET_SUBJECT = "setSubject";


export default {
  state: () => ({
    subjects: [],
    subject: {},
  }),
  getters: {
    subjects(state) {
      return state.subjects;
    },
    subject(state) {
      return state.subject;
    }
  },
  mutations: {
    [SET_LIST_SUBJECT](state, data) {
      state.subjects = data.data;
    },
    [SET_SUBJECT](state, data) {
      state.subject = data.data;
    }
  },
  actions: {
    [GET_LIST_SUBJECT](context,payload) {
      return new Promise(resolve => {
        ApiService.query(GET_LIST_SUBJECT,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_SUBJECT, data);
          }
          resolve(data);
        });
      });
    },
    [GET_LIST_SUBJECT_BY_GRADE](context,payload) {
      return new Promise(resolve => {
        ApiService.query(GET_LIST_SUBJECT_BY_GRADE,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_SUBJECT, data);
          }
          resolve(data);
        });
      });
    },
    [GET_ALL_SUBJECT](context) {
      return new Promise(resolve => {
        ApiService.query(GET_ALL_SUBJECT).then(({data}) => {
          if (data.status) {
            context.commit(SET_LIST_SUBJECT, data);
          }
          resolve(data);
        });
      });
    },
    [GET_DETAIL_SUBJECT](context, payload) {
      return new Promise(resolve => {
        ApiService.query(GET_DETAIL_SUBJECT,payload).then(({data}) => {
          if (data.status) {
            context.commit(SET_SUBJECT, data);
          }
          resolve(data);
        });
      });
    },
    [CREATE_SUBJECT](context, payload) {
      return new Promise(resolve => {
        ApiService.post(CREATE_SUBJECT, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [EDIT_SUBJECT](context, payload) {
      return new Promise(resolve => {
        ApiService.post(EDIT_SUBJECT, payload).then(({data}) => {
          resolve(data);
        });
      });
    },
    [DELETE_SUBJECT](context, id) {
      return new Promise(resolve => {
        ApiService.post(`${DELETE_SUBJECT}/${id}`).then(({data}) => {
          resolve(data);
        });
      });
    },
  }
}